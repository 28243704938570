import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import the styles

const Form = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState('requester');

    const handleSignIn = () => {
        // Perform any necessary actions (e.g., contract interaction, authentication)

        if (role === 'worker') {
            navigate('/worker-dashboard'); // Navigate to Worker dashboard
        } else if (role === 'requester') {
            navigate('/requester-dashboard'); // Navigate to Requester dashboard
        }
    };

    return (
        <div className="form-container">
            <h2>Fill in your details</h2>
            <form>
                <div className="input-group">
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" id="fullName" name="fullName" />
                </div>
                <div className="input-group">
                    <label htmlFor="email">E-Mail Address:</label>
                    <input type="email" id="email" name="email" />
                </div>
                <div className="input-group">
                    <label htmlFor="role">Role:</label>
                    <select
                        id="role"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value="requester">Requester</option>
                        <option value="worker">Worker</option>
                    </select>
                </div>
            </form>
            <div className="buttons">
                <button onClick={handleSignIn}>Sign In</button>
            </div>
        </div>
    );
};

export default Form;
