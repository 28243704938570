// import React, { createContext, useContext, useState } from 'react';

// const TaskContext = createContext();

// export const useTasks = () => useContext(TaskContext);

// export const TaskProvider = ({ children }) => {
//     const [tasks, setTasks] = useState([]);

//     const addTask = (task) => {
//         setTasks([...tasks, task]);
//     };

//     return (
//         <TaskContext.Provider value={{ tasks, addTask }}>
//             {children}
//         </TaskContext.Provider>
//     );
// };

import React, { createContext, useContext, useState } from 'react';

const TaskContext = createContext();

export const useTasks = () => useContext(TaskContext);

export const TaskProvider = ({ children }) => {
    const [tasks, setTasks] = useState([]);

    const addTask = (task) => {
        setTasks([...tasks, task]);
    };

    const updateTask = (updatedTasks) => {
        setTasks(updatedTasks);
    };

    return (
        <TaskContext.Provider value={{ tasks, addTask, updateTask }}>
            {children}
        </TaskContext.Provider>
    );
};
