import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import './styles.css';

function Navbar() {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        connectToMetamask();
    }, []);

    const connectToMetamask = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                setAccount(accounts[0]);
            } catch (error) {
                console.error('Error connecting to Metamask:', error);
            }
        } else {
            console.error('Metamask not available');
        }
    };

    return (
        <nav>
            <div className="navbar">
                <h1>ABC - Alipour's Blockchain Crowdsourcing</h1>
                <p>Connected Account: {account}</p>
                <ul className="nav-links">
                    <button><Link to="/">Home</Link></button>
                    <button><Link to="/worker-dashboard">Worker Dashboard</Link></button>
                    <button><Link to="/requester-dashboard">Requester Dashboard</Link></button>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
