import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Form from './components/SignForm';
import WorkerDashboard from './components/WorkerDashboard';
import RequesterDashboard from './components/RequesterDashboard';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/worker-dashboard" element={<WorkerDashboard />} />
          <Route path="/requester-dashboard" element={<RequesterDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
