import React, { useState, useEffect } from 'react';
import { useTasks } from '../TaskContext';
import Web3 from 'web3';
import './styles.css';

function RequesterDashboard() {
    const [activeTab, setActiveTab] = useState('requestTask');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskReward, setTaskReward] = useState('');
    const [workersNeeded, setWorkersNeeded] = useState(''); // New state for workers needed
    const [isTaskSubmitted, setIsTaskSubmitted] = useState(false);
    const { addTask } = useTasks();

    const handleSubmitTask = () => {
        const newTask = {
            id: Math.random().toString(),
            title: taskTitle,
            description: taskDescription,
            reward: taskReward,
            workersNeeded: workersNeeded,
            isTaskDone: false, // Initial value for isTaskDone
            isTaskValidated: false, // Initial value for isTaskValidated
            isTaskCompeleted: false, // Initial value for isTaskValidated
        };
        addTask(newTask);
        setTaskTitle('');
        setTaskDescription('');
        setTaskReward('');
        setWorkersNeeded('');
        setIsTaskSubmitted(true);
    };

    const { tasks, updateTask } = useTasks(); // Destructure updateTask method

    const handleCompeleteTask = (taskId) => {
        const updatedTasks = tasks.map((task) => {
            if (task.id === taskId) {
                return { ...task, isTaskCompeleted: true };
            }
            return task;
        });

        updateTask(updatedTasks);

        console.log(`Compeleted task with ID: ${taskId}`);
    };


    const [accountBalance, setAccountBalance] = useState(null);
    const web3 = new Web3(Web3.givenProvider || 'http://127.0.0.1:7545');

    useEffect(() => {
        const getAccountBalance = async () => {
            try {
                if (web3 && web3.currentProvider) {
                    const accounts = await web3.eth.getAccounts();
                    if (accounts.length > 0) {
                        const balanceWei = await web3.eth.getBalance(accounts[0]);
                        const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
                        setAccountBalance(balanceEth);
                    }
                }
            } catch (error) {
                console.error('Error fetching account balance:', error);
            }
        };

        getAccountBalance();
    }, [web3]);

    return (
        <div>
            <div className="dashboard-container">
                <div className="side-panel">
                    <ul className="tab-list">
                        <li
                            className={activeTab === 'requestTask' ? 'active' : ''}
                            onClick={() => handleTabChange('requestTask')}
                        >
                            Request a Task
                        </li>
                        <li
                            className={activeTab === 'pendingTasks' ? 'active' : ''}
                            onClick={() => handleTabChange('pendingTasks')}
                        >
                            Pending Tasks
                        </li>
                        <li
                            className={activeTab === 'accountBalance' ? 'active' : ''}
                            onClick={() => handleTabChange('accountBalance')}
                        >
                            Account Balance
                        </li>
                        <li
                            className={activeTab === 'completedTasks' ? 'active' : ''}
                            onClick={() => handleTabChange('completedTasks')}
                        >
                            Completed Tasks
                        </li>
                        <li
                            className={activeTab === 'report' ? 'active' : ''}
                            onClick={() => handleTabChange('report')}
                        >
                            Report
                        </li>
                    </ul>
                </div>
                <div className="content">
                    {activeTab === 'requestTask' && (
                        <div className="RequestTaskForm">

                            <h2>Request a Task</h2>

                            <form onSubmit={(e) => e.preventDefault()}>
                                <input
                                    type="text"
                                    value={taskTitle}
                                    onChange={(e) => setTaskTitle(e.target.value)}
                                    placeholder="Task Title"
                                    required
                                />
                                <textarea
                                    value={taskDescription}
                                    onChange={(e) => setTaskDescription(e.target.value)}
                                    placeholder="Task Description"
                                    required
                                />
                                <input
                                    type="text"
                                    value={taskReward}
                                    onChange={(e) => setTaskReward(e.target.value)}
                                    placeholder="Task Reward"
                                    required
                                />
                                <input
                                    type="number"
                                    value={workersNeeded}
                                    onChange={(e) => setWorkersNeeded(e.target.value)}
                                    placeholder="Number of Workers Needed"
                                    required
                                />
                                <button onClick={handleSubmitTask}>Submit Task</button>
                            </form>


                            {isTaskSubmitted && <p className="success-message">Task Submitted</p>}

                            <p><b>Note: </b>Reward will be divided among the workers</p>

                        </div>
                    )}
                    {activeTab === 'pendingTasks' && (
                        <div>
                            <h2>Pending Tasks</h2>
                            <ul className="tasks-list">
                                {tasks
                                    .filter(task =>
                                        task.isTaskDone &&
                                        task.isTaskValidated &&
                                        !task.isTaskCompeleted
                                    )
                                    .map(task => (
                                        <li key={task.id}>
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Reward: {task.reward}</p>
                                            <p>Workers Needed: {task.workersNeeded}</p>

                                            <p>{task.isTaskValidated}</p>

                                            <button onClick={() => handleCompeleteTask(task.id)}>Final Approval</button>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}

                    {activeTab === 'accountBalance' && (
                        <div>

                            <h2>Account Balance</h2>
                            <p>{accountBalance} ETH</p>

                        </div>
                    )}
                    {activeTab === 'completedTasks' && (
                        <div>

                            <h2>Compeleted Tasks</h2>
                            <ul className="tasks-list">
                                {tasks
                                    .filter(task =>
                                        task.isTaskDone &&
                                        task.isTaskValidated &&
                                        task.isTaskCompeleted
                                    )
                                    .map(task => (
                                        <li key={task.id}>
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Reward: {task.reward}</p>
                                            <p>Workers Needed: {task.workersNeeded}</p>
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    )}
                    {activeTab === 'report' && (
                        <div>Content for Report tab</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RequesterDashboard;
