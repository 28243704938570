import React, { useState, useEffect } from 'react';
import { useTasks } from '../TaskContext';
import Web3 from 'web3';

function WorkerDashboard() {
    const [activeTab, setActiveTab] = useState('availableTasks');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const { tasks, updateTask } = useTasks(); // Destructure updateTask method

    const handleReserveTask = (taskId) => {
        const updatedTasks = tasks.map((task) => {
            if (task.id === taskId) {
                return { ...task, isTaskDone: true };
            }
            return task;
        });

        updateTask(updatedTasks);
        console.log(`Done task with ID: ${taskId}`);
    };

    const handleValidateTask = (taskId) => {
        const updatedTasks = tasks.map((task) => {
            if (task.id === taskId) {
                return { ...task, isTaskValidated: true };
            }
            return task;
        });

        updateTask(updatedTasks);

        console.log(`Validated task with ID: ${taskId}`);
    };

    const handleGetReward = (taskId) => {
        console.log(`Reward for compeletion task with ID: ${taskId}`);
    };

    const [accountBalance, setAccountBalance] = useState(null);
    const web3 = new Web3(Web3.givenProvider || 'http://127.0.0.1:7545');

    useEffect(() => {
        const getAccountBalance = async () => {
            try {
                if (web3 && web3.currentProvider) {
                    const accounts = await web3.eth.getAccounts();
                    if (accounts.length > 0) {
                        const balanceWei = await web3.eth.getBalance(accounts[0]);
                        const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
                        setAccountBalance(balanceEth);
                    }
                }
            } catch (error) {
                console.error('Error fetching account balance:', error);
            }
        };

        getAccountBalance();
    }, [web3]);

    return (
        <div>
            <div className="dashboard-container">
                <div className="side-panel">
                    <ul className="tab-list">
                        <li
                            className={activeTab === 'availableTasks' ? 'active' : ''}
                            onClick={() => handleTabChange('availableTasks')}
                        >
                            Available Tasks
                        </li>
                        <li
                            className={activeTab === 'validationTasks' ? 'active' : ''}
                            onClick={() => handleTabChange('validationTasks')}
                        >
                            Validation Tasks
                        </li>
                        <li
                            className={activeTab === 'accountBalance' ? 'active' : ''}
                            onClick={() => handleTabChange('accountBalance')}
                        >
                            Account Balance
                        </li>
                        <li
                            className={activeTab === 'completedTasks' ? 'active' : ''}
                            onClick={() => handleTabChange('completedTasks')}
                        >
                            Completed Tasks
                        </li>
                        <li
                            className={activeTab === 'report' ? 'active' : ''}
                            onClick={() => handleTabChange('report')}
                        >
                            Report
                        </li>
                    </ul>
                </div>
                <div className="content">
                    {activeTab === 'availableTasks' && (
                        <div>

                            <h2>Available Tasks</h2>

                            <ul className="tasks-list">
                                {tasks
                                    .filter(task =>
                                        !task.isTaskDone &&
                                        !task.isTaskValidated &&
                                        !task.isTaskCompleted
                                    ) // Filter tasks with specified values
                                    .map(task => (
                                        <li key={task.id}>
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Reward: {task.reward}</p>
                                            <p>Workers Needed: {task.workersNeeded}</p>
                                            <button onClick={() => handleReserveTask(task.id)}>Reserve</button>
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    )}
                    {activeTab === 'validationTasks' && (
                        <div>

                            <h2>Validation Tasks</h2>
                            <ul className="tasks-list">
                                {tasks
                                    .filter(task =>
                                        task.isTaskDone &&
                                        !task.isTaskValidated &&
                                        !task.isTaskCompeleted
                                    )
                                    .map(task => (
                                        <li key={task.id}>
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Reward: {task.reward}</p>
                                            <p>Workers Needed: {task.workersNeeded}</p>
                                            <button onClick={() => handleValidateTask(task.id)}>Validate</button>
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    )}
                    {activeTab === 'accountBalance' && (
                        <div>

                            <h2>Account Balance</h2>
                            <p>{accountBalance} ETH</p>

                        </div>
                    )}
                    {activeTab === 'completedTasks' && (
                        <div>

                            <h2>Compeleted Tasks</h2>
                            <ul className="tasks-list">
                                {tasks
                                    .filter(task =>
                                        task.isTaskDone &&
                                        task.isTaskValidated &&
                                        task.isTaskCompeleted
                                    )
                                    .map(task => (
                                        <li key={task.id}>
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Reward: {task.reward}</p>
                                            <p>Workers Needed: {task.workersNeeded}</p>
                                            <button onClick={() => handleGetReward(task.id)}>Get Reward</button>
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    )}
                    {activeTab === 'report' && (
                        <div>Content for report tab</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WorkerDashboard;
